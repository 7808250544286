import axios from 'axios'
import Vue from 'vue'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
})

// 请求拦截器：在每次发送请求之前执行
request.interceptors.request.use(
  (config) => {
    config.withCredentials = true
    return config // 返回配置，继续发送请求
  },
  (error) => {
    return Promise.reject(error) // 请求错误时返回 Promise 拒绝
  },
)

request.interceptors.response.use(
  (response) => {
    window.response = response

    if (response.status === 200 && response.data.code === 200) {
      return response.data
    }
    return Promise.reject(response)
  },
  (error) => {
    Vue.prototype.$mmToast(error.response ? error.response.data.message : error.message)
    return error
  },
)

export default request
